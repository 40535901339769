import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { Box, Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Link } from "../style/Link";
import { Image } from "../style/Image";
import HeaderInner from "../components/HeaderInner";
import Meta from "../components/Meta";
import { Wysiwyg } from "../style/Wysiwyg";
import { parseContent } from "../utils/lang";

export default ({
  data,
  mobileMenuOpen,
  toggleMobileMenu,
  pageContext: { translations, links, lang, currentSlug },
}) => {
  const { frontmatter } = parseContent(data.markdownRemark, lang);

  return (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />
      <Wrapper bg="darkGreen" position="relative" zIndex={1000}>
        <HeaderInner
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          translations={translations}
          links={links}
          lang={lang}
          slug={currentSlug}
        />
      </Wrapper>

      <Wrapper bg="darkGreen" pt={[4, null, 5]} pb={[4, null, 5]} color="white">
        <Container pt={3}>
          <Heading as="h1" fontSize="h1" color="white" mb={[4, null, 3]}>
            {frontmatter.title}
          </Heading>

          <Flex pl={[0, null, null, null, "200px"]} flexWrap="wrap">
            <Flex width={[1, null, 1 / 2]} flexDirection="column">
              <Flex mb={[4, null, 5]} alignItems="flex-start">
                <Image
                  src={require("../assets/icons/email.svg")}
                  alt="e-mail"
                />

                <Box pt={2} pl={3}>
                  <Link
                    as="a"
                    fontWeight="medium"
                    fontSize={20}
                    href={`mailto:${frontmatter.mail}`}
                    color="cyberGreen"
                  >
                    {frontmatter.mail}
                  </Link>
                </Box>
              </Flex>
              <Flex mb={[4, null, 5]} alignItems="flex-start">
                <Image src={require("../assets/icons/phone.svg")} alt="tel" />

                <Box pt={2} pl={3}>
                  {frontmatter.phone.map((phone, index) => (
                    <Link
                      as="a"
                      display="block"
                      key={index}
                      fontSize={20}
                      fontWeight="medium"
                      color="lightTitaniumGrey"
                      href={`tel:${phone}`}
                    >
                      {phone}
                    </Link>
                  ))}
                </Box>
              </Flex>
            </Flex>

            <Flex width={[1, null, 1 / 2]} flexDirection="column">
              <Flex mb={[4, null, 5]} alignItems="flex-start">
                <Image
                  src={require("../assets/icons/home.svg")}
                  alt={translations.address}
                />

                <Box pt={2} pl={3}>
                  <Text
                    fontSize={20}
                    fontWeight="medium"
                    color="lightTitaniumGrey"
                  >
                    {frontmatter.company}
                  </Text>
                  <Text
                    color="lightTitaniumGrey"
                    dangerouslySetInnerHTML={{ __html: frontmatter.address }}
                    mb={3}
                  />

                  <Text color="lightTitaniumGrey">
                    <strong>{translations.contactCompanyId}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{frontmatter.ic}
                  </Text>
                  <Text color="lightTitaniumGrey" mb={3}>
                    <strong>{translations.contactVatId}</strong>
                    &nbsp;&nbsp;&nbsp;{frontmatter.dic}
                  </Text>

                  <Text color="lightTitaniumGrey">{frontmatter.legal}</Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper py={[4, null, 5]}>
        <Container>
          <Flex pl={[0, null, null, null, "200px"]} flexWrap="wrap">
            <Flex
              width={[1, null, 1 / 2]}
              flexDirection="column"
              mb={[4, null, null, 0]}
            >
              <Heading as="h2" fontSize="h3" mb={3}>
                {translations.contactSecureComm}
                <br/>
                {translations.contactKeys}
              </Heading>
              {frontmatter.keys.map((item, index) => (
                <Box key={index}>
                  <Link
                    as="a"
                    variant="underline"
                    href={`../../certs/${item.link}`}
                    download
                    mb={index + 1 === frontmatter.keys.length && 4}
                  >
                    {item.name}
                  </Link>
                </Box>
              ))}

              <Heading as="h2" fontSize="h3" mb={2}>
                {translations.contactDS}
              </Heading>
              <Box>
                <Wysiwyg
                  dangerouslySetInnerHTML={{ __html: frontmatter.dataBox }}
                />
              </Box>
            </Flex>

            <Flex width={[1, null, 1 / 2]} flexDirection="column">
              <Heading as="h2" fontSize="h3" mb={3}>
                {translations.contactBankAccount}
              </Heading>
              <Text>
                <div
                  dangerouslySetInnerHTML={{ __html: frontmatter.bankAccount }}
                />
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
    </Fragment>
  );
};
export const pageQuery = graphql`
  query CONTACT_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        title_cz
        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }
        mail
        phone
        address
        ic
        dic
        legal
        legal_cz
        company
        keys {
          name
          link
        }
        keys_cz {
          name
          link
        }
        bankAccount
        bankAccount_cz
        dataBox
      }
    }
  }
`;
